import { Request } from 'wiw/utils';
import { getToken, getUserId } from './identifiers';

export const request = function (root) {
  const headers = {};

  if (getToken()) {
    headers['W-Token'] = getToken();
    headers['Authorization'] = getToken();
  }

  if (getUserId()) {
    headers['W-UserId'] = getUserId();
  }

  return new Request({
    headers: headers,
  }, root);
};

export default {
  request,
};
